<template>
  <div class="intro">
    <van-nav-bar title="重要通知" >
      <template #left>
        <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
      </template>
    </van-nav-bar>
    <div class="notice_text">{{depiction}}</div>
  </div>
</template>

<script>
  import { NavBar, Icon} from 'vant'
  export default {
    name: "notice",
    components:{
      'van-icon': Icon,
      'van-nav-bar': NavBar,
    },
    data() {
      return {
        scenicKey:'',
        apis:{
          scenic:'/seller/find',//获取notice
        },
        depiction:'1111',
      }
    },
    created(){

    },
    methods: {
      exit(){
        this.$router.go(-1)
      },
      loadScenic(){//获取景点详情
        const me = this;
        me.scenic = {};
        me.imgs = [];
        me.$get({
          url:me.apis.scenic,
          params: {
            sellerId: me.scenicKey,
            token: me.$global.token
          }
        }).then(rsp => {
          me.depiction = rsp.notice;
        });
      },
    },
    mounted() {
      const me = this;
      me.scenicKey  = me.$route.query.key;
      me.loadScenic();
    },
  }
</script>

<style scoped lang="scss">
  .intro{
    font-size: 12px;
  }
  .notice_text{
    padding: 56px 20px 10px 20px;
    font-size: 14px;
    color: #575757;
    line-height: 24px;
  }
</style>
